import "./App.scss";

function App() {
  return (
    <div className="ashhome__container">
      <div className="ashhome__text">
        <div className="ashhome__text-title">My Agency Budget</div>
        <div className="ashhome__text-subtitle">
          <i>Coming soon...</i>
        </div>
      </div>
    </div>
  );
}

export default App;
